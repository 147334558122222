import { Button } from "@socar-inc/frontend-design-system";
import axios from "axios";
import cookie from "js-cookie";
import { useState } from "react";
import {
  DevToolsCommonHalfView,
  type DevToolsCommonHalfViewProps
} from "./DevToolsCommonHalfView";
import { DevToolsTextField } from "./DevToolsTextField";

type DevToolsAccessTokenHalfViewProps = Pick<
  DevToolsCommonHalfViewProps,
  "isDevToolsOpen" | "onClose"
>;

export const DevToolsAccessTokenHalfView = ({
  isDevToolsOpen,
  onClose
}: DevToolsAccessTokenHalfViewProps) => {
  const [email, setEmail] = useState("");

  const handleChange = (value: string) => {
    setEmail(value);
  };

  const handleSubmit = async () => {
    const response = await axios.get(`/GetAccessToken/${email}`);

    if (response.data === "해당 회원이 존재하지 않습니다.") {
      alert("해당 회원이 존재하지 않습니다.");
    } else {
      cookie.set("auth_token", response.data);
      alert(`액세스 토큰: ${response.data} 쿠키 주입 성공!`);
      onClose();
    }
  };

  return (
    <DevToolsCommonHalfView
      title="액세스 토큰 발급"
      isDevToolsOpen={isDevToolsOpen}
      onClose={onClose}
    >
      <DevToolsTextField
        placeholder="이메일을 입력해주세요."
        onChange={handleChange}
      />
      <Button
        onClick={handleSubmit}
        text="발급"
        type="regularFill"
        color="blue"
      />
    </DevToolsCommonHalfView>
  );
};
