import { Button } from "@socar-inc/frontend-design-system";
import { openNewWebView } from "@socar-inc/web-frontend-libraries";
import { moveToExternal } from "@src/utils/moveToApp/moveToExternal";
import { moveToInternal } from "@src/utils/moveToApp/moveToInternal";
import { useRouter } from "next/router";
import { useState } from "react";
import {
  DevToolsCommonHalfView,
  type DevToolsCommonHalfViewProps
} from "./DevToolsCommonHalfView";
import { DevToolsTextField } from "./DevToolsTextField";

const moveToPageTypeList = [
  "push",
  "replace",
  "openNewWebView",
  "moveToInternal",
  "moveToExternal"
] as const;

type MoveToPageType = (typeof moveToPageTypeList)[number];

type DevToolsMoveToPageHalfViewProps = Pick<
  DevToolsCommonHalfViewProps,
  "isDevToolsOpen" | "onClose"
>;

export const DevToolsMoveToPageHalfView = ({
  isDevToolsOpen,
  onClose
}: DevToolsMoveToPageHalfViewProps) => {
  const router = useRouter();
  const [url, setUrl] = useState("");

  const handleUrlChange = (value: string) => {
    setUrl(value);
  };

  const handleSubmit = (type: MoveToPageType) => {
    switch (type) {
      case "push":
        router.push(url);
        break;
      case "replace":
        router.replace(url);
        break;
      case "openNewWebView":
        openNewWebView(url);
        break;
      case "moveToInternal":
        moveToInternal(url);
        break;
      case "moveToExternal":
        moveToExternal(url);
        break;
      default:
        router.push(url);
    }
  };

  return (
    <DevToolsCommonHalfView
      title="페이지 이동하기"
      isDevToolsOpen={isDevToolsOpen}
      onClose={onClose}
    >
      <DevToolsTextField
        onChange={handleUrlChange}
        placeholder="이동할 URL 또는 경로를 입력하세요."
      />
      {moveToPageTypeList.map((type) => (
        <Button
          key={type}
          onClick={() => handleSubmit(type)}
          text={`[${type}] 이동하기`}
          type="regularFill"
          color="blue"
          style={{ width: "100%", height: "32px", fontSize: "14px" }}
        />
      ))}
    </DevToolsCommonHalfView>
  );
};
