import "src/styles/global.css";
import "src/utils/sprintf";
import { AsyncBoundary } from "@src/components/AsyncBoundary";
import { DefaultHeader } from "@src/components/DefaultHeader";
import { DefaultScript } from "@src/components/DefaultScript";
import { DevTools } from "@src/components/DevTools";
import { useInterfaceBetweenApp } from "@src/hooks/useInterfaceBetweenApp";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { datadogNextjs } from "configs/datadog-config";
import type { AppProps } from "next/app";
import { DefaultSeo } from "next-seo";
import { useEffect, useState } from "react";

const OPEN_GRAPH = {
  type: "website",
  title: "title",
  description: "description",
  url: "https://www.url.ie/"
};

function MyApp({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
            refetchOnWindowFocus: false,
            throwOnError: true
          }
        }
      })
  );

  const { initDatadog } = datadogNextjs();
  const { initInterfaceBetween } = useInterfaceBetweenApp();

  useEffect(() => {
    const initEruda = async () => {
      const { default: eruda } = await import("eruda");
      eruda.init();
    };

    if (process.env.NEXT_PUBLIC_MODE !== "local") {
      initDatadog();
    }
    if (process.env.NEXT_PUBLIC_MODE !== "prod") {
      initEruda();
    }
    initInterfaceBetween();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <AsyncBoundary>
        <DefaultHeader />
        <DefaultScript />
        <DefaultSeo openGraph={OPEN_GRAPH} />
        <Component {...pageProps} />
        <ReactQueryDevtools initialIsOpen={false} />
      </AsyncBoundary>
      <DevTools />
    </QueryClientProvider>
  );
}

export default MyApp;
