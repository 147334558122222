export const DevToolsCurrentURLViewer = () => {
  const currentURL = decodeURIComponent(window.location.href);

  const handleCopyCurrentURL = () => {
    navigator.clipboard.writeText(currentURL);
    alert("URL이 복사되었습니다.");
  };

  return (
    <button type="button">
      <div
        onClick={handleCopyCurrentURL}
        className="tw-relative tw-flex tw-items-center tw-text-14 tw-w-full tw-h-[56px] tw-bg-white tw-p-12 tw-text-black tw-whitespace-nowrap tw-overflow-auto"
      >
        {currentURL}
      </div>
    </button>
  );
};
