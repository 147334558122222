import { Icon } from "@socar-inc/frontend-design-system";
import type { ReactNode } from "react";
import { Portal } from "../Portal";
import { When } from "../When";

export interface DevToolsCommonHalfViewProps {
  isDevToolsOpen: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
  height?: number | string;
}

export const DevToolsCommonHalfView = ({
  isDevToolsOpen,
  onClose,
  title,
  height,
  children
}: DevToolsCommonHalfViewProps) => {
  return (
    <Portal>
      <When if={isDevToolsOpen}>
        <div className="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-z-50">
          <div
            className="tw-relative tw-max-h-[480px] tw-min-h-[360px]
      tw-rounded-t16 tw-bg-grey060 tw-overflow-auto tw-rounded-t-16"
            style={{
              height: typeof height === "number" ? `${height}px` : height
            }}
          >
            <div className="tw-sticky tw-top-0 tw-left-0 tw-flex tw-justify-between tw-items-center tw-px-24 tw-py-20 tw-bg-grey060 tw-z-10">
              <div className="title1 tw-text-white">{title}</div>
              <button type="button" onClick={onClose}>
                <Icon icon="ic24_close" color="white" />
              </button>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-16 tw-h-full tw-px-24 tw-py-20">
              {children}
            </div>
          </div>
        </div>
      </When>
    </Portal>
  );
};
