import { useState } from "react";
import { When } from "../When";
import { DevToolsHalfView } from "./DevToolsHalfView";
import { DraggableDevButton } from "./DraggableDevButton";

const IS_DEV = process.env.NEXT_PUBLIC_MODE !== "prod";

export const DevTools = () => {
  const [isDevToolsOpen, setIsDevToolsOpen] = useState(false);

  const handleDevToolsHalfViewClose = () => {
    setIsDevToolsOpen(false);
  };

  const handleOpenDevToolsHalfView = () => {
    setIsDevToolsOpen(true);
  };

  return (
    <When if={IS_DEV}>
      <DraggableDevButton onClick={handleOpenDevToolsHalfView} />
      <DevToolsHalfView
        isDevToolsOpen={isDevToolsOpen}
        onClose={handleDevToolsHalfViewClose}
      />
    </When>
  );
};
