import Head from "next/head";

export const DefaultHeader = () => (
  <>
    <Head>
      <title>쏘카일레클</title>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE-Edge" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
      />
    </Head>
  </>
);
