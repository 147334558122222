import { Button } from "@socar-inc/frontend-design-system";
import { useState } from "react";
import { DevToolsAccessTokenHalfView } from "./DevToolsAccessTokenHalfView";
import {
  DevToolsCommonHalfView,
  type DevToolsCommonHalfViewProps
} from "./DevToolsCommonHalfView";
import { DevToolsCurrentURLViewer } from "./DevToolsCurrentURLViewer";
import { DevToolsMoveToPageHalfView } from "./DevToolsMoveToPageHalfView";

type OpenedHalfViewType = "getAccessToken" | "moveToPage";

type DevToolsHalfViewProps = Pick<
  DevToolsCommonHalfViewProps,
  "isDevToolsOpen" | "onClose"
>;

export const DevToolsHalfView = ({
  isDevToolsOpen,
  onClose
}: DevToolsHalfViewProps) => {
  const [openedHalfViewType, setOpenedHalfViewType] =
    useState<OpenedHalfViewType | null>(null);

  const handleHalfViewClose = () => {
    setOpenedHalfViewType(null);
  };

  const handleOpenGetAccessTokenHalfView = () => {
    setOpenedHalfViewType("getAccessToken");
  };

  const handleOpenMoveToPageHalfView = () => {
    setOpenedHalfViewType("moveToPage");
  };

  const handleReloadButtonClick = () => {
    window.location.reload();
  };

  return (
    <>
      <DevToolsCommonHalfView
        title="Elecle DevTools"
        isDevToolsOpen={isDevToolsOpen}
        onClose={onClose}
      >
        <DevToolsCurrentURLViewer />
        <Button
          onClick={handleOpenGetAccessTokenHalfView}
          text="액세스 토큰 발급"
          type="regularFill"
          color="blue"
        />
        <Button
          onClick={handleOpenMoveToPageHalfView}
          text="페이지 이동하기"
          type="regularFill"
          color="blue"
        />
        <Button
          onClick={handleReloadButtonClick}
          text="새로고침"
          type="regularFill"
          color="blue"
        />
      </DevToolsCommonHalfView>
      <DevToolsAccessTokenHalfView
        isDevToolsOpen={openedHalfViewType === "getAccessToken"}
        onClose={handleHalfViewClose}
      />
      <DevToolsMoveToPageHalfView
        isDevToolsOpen={openedHalfViewType === "moveToPage"}
        onClose={handleHalfViewClose}
      />
    </>
  );
};
